import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Skli_NewsShot from './skeletonComp/Skli_NewsShot'

function NewsShot() {
  const API = `https://www.zprajshahi.gov.bd/zpr/data/notice`
  const [mainData, setmainData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const getAPIData = async (api) => {
    try {
      const res = await axios.get(api)
      const Data = await res.data
      setmainData(Data)
      setIsLoading(false)
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getAPIData(API)
  }, []);
  return (
    <>
      <div className="newsCard">
        <div className="tittle">
          <strong>খবর:</strong>
        </div>
        <ul>
          {
            isLoading
            ?
            <Skli_NewsShot/>
            :
            <>
              {
                mainData.splice(0, 10).map((e) => {
                  const { title, id, notice_date } = e
                  return (
                    <>
                      <li><Link to={`/notice/details/${id}`}>{title}</Link> <i>({notice_date})</i></li>
                    </>
                  )
                })
              }
            </>
          }
        </ul>
        <Link class="btn right" to="/notices" title="সকল খবর">সকল</Link>
      </div>
    </>
  )
}

export default NewsShot