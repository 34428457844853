import React from 'react'
import logo from '../assets/img/zila_parishad_rajshahi_logo.png'
import SlIMG1 from "../assets/img/2.webp"
import SlIMG2 from "../assets/img/3.webp"
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import { Autoplay, Pagination, EffectFade } from "swiper";
import { useState } from 'react'
import axios from 'axios'
import { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'

function Slider() {
  const SliderImgAPi = `https://zprajshahi.gov.bd/zpr/data/slider_image`;
  const SliderimgUrl = `https://zprajshahi.gov.bd/zpr/assets/zprslider/`
  const API_logo = `https://www.zprajshahi.gov.bd/zpr/data/logo`;
  const imgUrl = `https://www.zprajshahi.gov.bd/zpr/assets/logo/`;
  const [mainLogoData, setmainLogoData] = useState([]);
  const [isLoading1, setIsLoading1] = useState(true)
  const [mainSliderData, setMainSliderData] = useState([]);
  const [isLoading2, setIsLoading2] = useState(true)

  const fatchAllData = async () => {
    const getLogoAPI = axios.get(API_logo)
    const getSliderImgs = axios.get(SliderImgAPi)

    axios.all([getLogoAPI, getSliderImgs]).then(
      axios.spread((...allAPIData) => {
        const coverLogoAPIData = allAPIData[0].data
        setmainLogoData(coverLogoAPIData)
        setIsLoading1(false)
        const SliderAPIData = allAPIData[1].data
        setMainSliderData(SliderAPIData)
        setIsLoading2(false)
      })
    )
  }


  useEffect(() => {
    fatchAllData()
  }, [])
  return (
    <>
      <div className="sliderPart">
        <div className="content">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            effect={"fade"}
            modules={[Autoplay, Pagination, EffectFade]}
            className="mySwiper bigswiper"
          >

            {
              isLoading2
                ?
                <Skeleton width={'100%'} height={'215px'} baseColor="green" highlightColor='#06cd06' />
                :
                <>
                  {
                    mainSliderData.map((e) => {
                      const {image} = e
                      return (
                        <>
                          <SwiperSlide>
                            <img src={SliderimgUrl+image} alt="" />
                          </SwiperSlide>
                        </>
                      )
                    })
                  }
                </>
            }
          </Swiper>
        </div>
        <div className="logo_text">
          {
            isLoading1
              ?
              <Link to="/" className='row'>
                  <Skeleton height='70px' width={'70px'} />
                  <div className="text">
                    <h1><Skeleton height={'25px'} width={'250px'} /></h1>
                  </div>
                </Link>
              :
              <>
                <Link to="/" className='row'>
                  <img src={imgUrl + mainLogoData[0].image} alt="" />
                  <div className="text">
                    <h1>{mainLogoData[0].name}</h1>
                  </div>
                </Link>
              </>
          }

        </div>
      </div>
    </>
  )
}

export default Slider
