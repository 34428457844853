import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
// Images
import img_minster from "../assets/img/padmabanner.jpg"
import { useState } from 'react'
import TabTittle from '../components/TabTittle'
import SideBar from '../components/SideBar'
import MainHeader from '../components/MainHeader'
import CommentImg from "../assets/img/comment.gif"
import axios from 'axios'
import { useEffect } from 'react'
import SkliPhotoGlry from '../components/skeletonComp/Skli_PgotoGlry'
import FilderCatagory from '../components/FilterCatagory'
import ToploaderBar from '../components/ToploaderBar'

function PhotoGy() {
    const API = `https://www.zprajshahi.gov.bd/zpr/data/gallery`;
    const imgUrl = `https://www.zprajshahi.gov.bd/zpr/assets/zprgallery/`
    TabTittle('ফটো গ্যালারী | জেলা পরিষদ, রাজশাহী।')
    const [mainData, setMainData] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const getAPIData = async (api) => {
        try {
            const res = await axios.get(api)
            const Data = await res.data
            setMainData(Data)
            setIsLoading(false)
        } catch (error) {
            console.log(error.message);
        }
    }
    useEffect(() => {
        getAPIData(API)
    }, [])
    // ------Image Popup------------
    const [model, setModel] = useState(false)
    const [tempimgSrc, setTempimgSrc] = useState('')
    const getSingleImgURL = (url) => {
        console.log(url);
        setModel(true)
        setTempimgSrc(url)
    }
    return (
        <>
            <ToploaderBar />
            <div className='colam'>
                <div className="pageTittle">
                    <h2><Link to="/" >Hme</Link>/Photo</h2>
                </div>
                <div className="videoPart photoGy">
                    <div className="cstittle">
                        <h2>ফটো গ্যালারী</h2>
                    </div>
                    {/* <FilderCatagory/>
                            <br /> */}
                    <div className={model ? "modelSingleImg open" : "modelSingleImg"} >
                        <div className="cls_btn" onClick={() => setModel(false)} >+</div>
                        <div className="img">
                            <img src={tempimgSrc} alt="" />
                        </div>
                    </div>
                    <div className="imgBox">
                        {
                            isLoading
                                ?
                                <SkliPhotoGlry />
                                :
                                <>
                                    {
                                        mainData.map((e) => {
                                            const { image, s_cat } = e
                                            return (
                                                <>
                                                    <div className="card">
                                                        <div className="imgPart" onClick={() => getSingleImgURL(imgUrl + image)}>
                                                            <img src={imgUrl + image} alt="" />
                                                        </div>
                                                        <div className="tittle">{s_cat}</div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </>
                        }
                    </div>
                </div>
            </div>
            {/* <div className="optionsPart">
                <div className="comment">
                    <img src={CommentImg} alt="" />
                </div>
            </div> */}
        </>
    )
}

export default PhotoGy
