import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import TabTittle from '../components/TabTittle'
import ToploaderBar from '../components/ToploaderBar'
import MainAPI from '../assets/APIs/mainApi'


function NoSurvices() {
    return (
        <>
            <ToploaderBar />
            <div className='colam'>
                <h1>কোন তথ্য নেই!</h1>
            </div>
        </>
    )
}

export default NoSurvices