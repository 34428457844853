import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import printImg from '../assets/img/print_btn.webp'
import MainAPI from '../assets/APIs/mainApi'
import { useState } from 'react'
import TabTittle from '../components/TabTittle'
import SideBar from '../components/SideBar'
import MainHeader from '../components/MainHeader'
import CommentImg from "../assets/img/comment.gif"
import axios from 'axios'
import SkliMemberList from '../components/skeletonComp/Skli_MemberList'
import ToploaderBar from '../components/ToploaderBar'


function Member() {
    const API = `https://www.zprajshahi.gov.bd/zpr/data/parishod`
    const [mainData, setmainData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const getAPIData = async (api) => {
        try {
            const res = await axios.get(api)
            const Data = await res.data
            setmainData(Data)
            setIsLoading(false)
        } catch (error) {
            console.log(error.message);
        }
    }
    useEffect(() => {
        getAPIData(API)
    }, []);
    TabTittle('জেলা পরিষদের সংরক্ষিত ও সাধারণ সদস্যদের তালিকা')
    return (
        <>
            <ToploaderBar />
            <div className='colam'>
                <div className="pageTittle">
                    <h2><Link to="/" >Hme</Link>/Members</h2>
                </div>
                <div className="newcContent">
                    <div className="tittle text-cente"><h1>জেলা পরিষদের সংরক্ষিত ও সাধারণ সদস্যদের তালিকা</h1> <button title='print'><img src={printImg} alt="" /></button></div>
                    {/* <h1>{mainData.Straps[0].photo}</h1> */}
                    <table>
                        <tr className='header'>
                            <th>ক্রমিক</th>
                            <th>নাম</th>
                            <th>পদবী</th>
                            <th>উপজেলা</th>
                            <th>মোবাইল নম্বর</th>
                        </tr>
                        {
                            isLoading
                                ?
                                <SkliMemberList />
                                :
                                <>
                                    {
                                        mainData.map((e, i) => {
                                            const { name, designation, upozilla, mobile } = e
                                            return (
                                                <>
                                                    <tr>
                                                        <td className='text-center'>{i + 1}</td>
                                                        <td>{name}</td>
                                                        <td>{designation}</td>
                                                        <td>{upozilla}</td>
                                                        <td>{mobile} </td>
                                                    </tr>
                                                </>
                                            )
                                        })
                                    }
                                </>
                        }
                    </table>
                </div>
            </div>
            {/* <div className="optionsPart">
                <div className="comment">
                    <img src={CommentImg} alt="" />
                </div>
            </div> */}
        </>
    )
}

export default Member
