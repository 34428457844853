const StrafReducer = (state, action) =>{
    switch (action.type) {
        case "SET_LOADING":
            return{
                ...state,
                isLoading: true,
            }
            
        case "SET_API_DATA":
            return{
                ...state,
                isLoading: true,
                strafs: action.payload,
            }
            
        case "API_ERROR":
            return{
                ...state,
                isLoading: false,
                isErroe: true,
            }
            
        default:
            return state
    }
}
export default StrafReducer