import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import MainHeader from '../components/MainHeader'
import SideBar from '../components/SideBar'
import CommentImg from "../assets/img/comment.gif"
import TabTittle from '../components/TabTittle'
import ToploaderBar from '../components/ToploaderBar'

function DesitalServices() {
    TabTittle('ডিজিটাল সেন্টার | জেলা পরিষদ, রাজশাহী।')
    return (
        <>
            <ToploaderBar />
            <div className='colam'>
                <div className="pageTittle">
                    <h2><Link to="/" >Hme</Link>/Desital Center</h2>
                </div>
                <div className="videoPart desitalServices">
                    <div className="cstittle my-2">
                        <h2>ডিজিটাল সেন্টার</h2>
                    </div>
                    <div className="contentPart">
                        <h1>জেলা পরিষদ  ডিজিটাল সেন্টার (ZPDC) স্থাপন করা হয়েছে যার মাধ্যমে বিভিন্ন ই–সেবা দেওয়া হচ্ছে।যেমনঃ</h1>
                        <ul>
                            <li>কম্পিউটার ও ইন্টারনেট বিষয়ক প্রশিক্ষণ প্রদান</li>
                            <li>ই-মেইলের মাধ্যমে দেশ-বিদেশে দ্রুত সময়ে যোগাযোগ ও ভিডিও কনফারেন্সের সুবিধা প্রদানসহ ইন্টারনেটও</li>
                        </ul>
                        <h2>জেলা পরিষদ ডিজিটাল সেন্টার উদ্দ্যেক্তাঃ</h2>
                        <div className="scroll-x">
                            <table>
                                <tr className='header'>
                                    <th>ক্রমিক নং</th>
                                    <th>নাম</th>
                                    <th>হইতে</th>
                                    <th>পর্যন্ত</th>
                                    <th>ছবি</th>
                                </tr>
                                <tr>
                                    <td rowSpan={2}>01</td>
                                    <td >-----------------</td>
                                    <td rowSpan={2}>২৫.০৭.২০১৮</td>
                                    <td rowSpan={2}></td>
                                    <td rowSpan={2}></td>
                                </tr>
                                <tr>
                                    <td>০১৭১২০০৬৩৩৮</td>
                                </tr>
                                <tr>
                                    <td rowSpan={2}>01</td>
                                    <td >----------------</td>
                                    <td rowSpan={2}>২৫.০৭.২০১৮</td>
                                    <td rowSpan={2}></td>
                                    <td rowSpan={2}></td>
                                </tr>
                                <tr>
                                    <td>০১৭১২০০৬৩৩৮</td>
                                </tr>
                                <tr>
                                    <td rowSpan={2}>01</td>
                                    <td >--------------</td>
                                    <td rowSpan={2}>২৫.০৭.২০১৮</td>
                                    <td rowSpan={2}></td>
                                    <td rowSpan={2}></td>
                                </tr>
                                <tr>
                                    <td>০১৭১২০০৬৩৩৮</td>
                                </tr>
                            </table>
                        </div>

                    </div>

                </div>
            </div>
            {/* <div className="optionsPart">
                <div className="comment">
                    <img src={CommentImg} alt="" />
                </div>
            </div> */}
        </>
    )
}

export default DesitalServices