import React from 'react';
import Iframe from 'react-iframe'; // Ensure you have react-iframe installed

const VideoGallery = ({ videoUrls }) => {
  return (
    <div className="videoPart">
      <div className="tittle">
        <h2>ভিডিও গ্যালারী</h2>
      </div>
      <div className="grid">
        {videoUrls.map((url, index) => (
          <div className={`videoDiv videoDiv${index + 1}`} key={index}>
            <Iframe url={url} width='100%' height='100%' />
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoGallery;
