import React from 'react'
import Skeleton from 'react-loading-skeleton'

function Skli_Discribe() {
    return (
        <>
            <div className='colam describe'>
                <h1><Skeleton /></h1>
                <div className="content">
                    <div className="memberDit">
                        <div className="imgpart rounded">
                            <Skeleton width='200px' height='200px' />
                        </div>
                        <div className="bayo">
                            <p><Skeleton count={5} /></p>
                        </div>
                    </div>
                    <div className="maberTable">
                        <table>
                            <tr className='header'>
                                <th><Skeleton /></th>
                                <th><Skeleton /></th>
                            </tr>
                            <tr>
                                <td><Skeleton /></td>
                                <td><Skeleton /></td>
                            </tr>
                            <tr>
                                <td><Skeleton /></td>
                                <td><Skeleton /></td>
                            </tr>
                            <tr>
                                <td><Skeleton /></td>
                                <td><Skeleton /></td>
                            </tr>
                            <tr>
                                <td><Skeleton /></td>
                                <td><Skeleton /></td>
                            </tr>
                            <tr>
                                <td><Skeleton /></td>
                                <td><Skeleton /></td>
                            </tr>
                            <tr>
                                <td><Skeleton /></td>
                                <td><Skeleton /></td>
                            </tr>
                            <tr>
                                <td><Skeleton /></td>
                                <td><Skeleton /></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Skli_Discribe