import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import printImg from '../assets/img/print_btn.webp'
import { useState } from 'react'
import TabTittle from '../components/TabTittle'
import axios from 'axios'
import SkliStaff from '../components/skeletonComp/Skli_Staff'
import ToploaderBar from '../components/ToploaderBar'

function Straps() {
    TabTittle('কর্মকর্তা ও কর্মচারীর তথ্য | জেলা পরিষদ, রাজশাহী।')
    const imgURL = `https://www.zprajshahi.gov.bd/zpr/assets/zprstaff/`
    const API = `https://www.zprajshahi.gov.bd/zpr/data/staff`
    const [mainData, setmainData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const getAPIData = async (api) => {
        try {
            const res = await axios.get(api)
            const Data = await res.data
            setmainData(Data)
            setIsLoading(false)
        } catch (error) {
            console.log(error.message);
        }
    }
    useEffect(() => {
        getAPIData(API)
    }, []);
    return (
        <>
            <ToploaderBar />
            <div className='colam'>
                <div className="pageTittle">
                    <h2><Link to="/" >Hme</Link>/Workers</h2>
                </div>
                <div className="newcContent">
                    <div className="tittle text-cente"><h1>জেলা পরিষদের অর্গানোগ্রাম ভূক্ত কর্মকর্তা ও কর্মচারীর তথ্য 111</h1> <button title='print'><img src={printImg} alt="" /></button></div>
                    <table>
                        <tr className='header'>
                            <th>ক্রমিক</th>
                            <th>নাম</th>
                            <th>পদবী</th>
                            <th>মোবাইল</th>
                            <th>ছবি</th>
                        </tr>
                        {
                            isLoading
                                ?
                                <SkliStaff />
                                :
                                <>
                                    {
                                        mainData.map((e, i) => {
                                            const { name, id, designation, mobile, image } = e
                                            return (
                                                <>
                                                    <tr>
                                                        <td rowSpan={'2'} className='text-center'>{i + 1}</td>
                                                        <td>{name}</td>
                                                        <td className='text-center'>{designation}</td>
                                                        <td className='text-center'><a href={`tel:${mobile}`}>{mobile}</a></td>
                                                        <td className='text-center'> <img src={imgURL + image} alt="" /> </td>
                                                    </tr>
                                                    <tr>
                                                    </tr>

                                                </>
                                            )
                                        })
                                    }
                                </>
                        }

                    </table>
                </div>
            </div>
            {/* <div className="optionsPart">
                <div className="comment">
                    <img src={CommentImg} alt="" />
                </div>
            </div> */}
        </>
    )
}

export default Straps
