import React from 'react'
import Skeleton from 'react-loading-skeleton'

function Skli_MemberCard() {
    return (
        <>
            <div className="memderCard">
                <div className="tittle"><Skeleton baseColor="#4ba614" /></div>
                <div className="img">
                    <Skeleton height="230px" width="100%" />
                </div>
                <div className="reg"><span><Skeleton /></span></div>
                <h2><Skeleton baseColor='#3f3e3e' /></h2>
            </div>
        </>
    )
}

export default Skli_MemberCard
