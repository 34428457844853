import React from 'react'
import Skeleton from 'react-loading-skeleton'

function SkliNoticeBox() {
    return (
        <>
            <div className="notice-board-ticker">
                <ul className='styleList'>
                    <li><Skeleton width="90%" /></li>
                    <li><Skeleton width="50%" /></li>
                    <li><Skeleton width="70%" /></li>
                    <li><Skeleton width="80%" /></li>
                    <li><Skeleton width="60%" /></li>
                </ul>
            </div>
        </>
    )
}

export default SkliNoticeBox