import React, { useState } from 'react';

function CopyClipboardButton({ text }) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 1500); // Reset copied state after 1.5 seconds
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  return (
    <div style={{width: "full", display: "grid", justifyItems: "center"}}>
      <button style={{fontSize: "20px", cursor: "pointer"}} onClick={copyToClipboard}>
        {copied ? '✔️' : '📄'}
      </button>
    </div>
  );
}

export default CopyClipboardButton;
