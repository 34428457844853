import React from 'react'
import NavBar from './NavBar'
import Slider from './Slider'
import TopHeader from './TopHeader2'

function MainHeader() {


    return (
        <>
            <TopHeader />
            <Slider />
            <NavBar />
        </>
    )
}

export default MainHeader
