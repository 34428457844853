import React, { useEffect } from 'react'
import MainAPI from '../assets/APIs/mainApi'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import TabTittle from '../components/TabTittle'
import SideBar from '../components/SideBar'
import MainHeader from '../components/MainHeader'
import CommentImg from "../assets/img/comment.gif"
import axios from 'axios'
import Skeleton from 'react-loading-skeleton'
import Skli_law from '../components/skeletonComp/Skli_Law'
import ToploaderBar from '../components/ToploaderBar'
function Law() {
    TabTittle("আইন বিধিমালা")
    const API = `https://www.zprajshahi.gov.bd/zpr/data/law`
    const pdfURL = `https://www.zprajshahi.gov.bd/zpr/assets/zprlows/`
    const [mainData, setmainData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const getAPIData = async (api) => {
        try {
            const res = await axios.get(api)
            const Data = await res.data
            setmainData(Data)
            setIsLoading(false)
        } catch (error) {
            console.log(error.message);
        }
    }
    useEffect(() => {
        getAPIData(API)
    }, []);
    return (
        <>
            <ToploaderBar />
            <div className="colam">
                <div className="pageTittle">
                    <h2><Link to="/" >Hme</Link>/Law</h2>
                </div>
                <div className="tittle">
                    <h1>আইন বিধিমালা</h1>
                </div>
                <table className='table'>
                    <tr className='header'>
                        <th>বিস্তারিত</th>
                        <th>তারিখ</th>
                        <th>ডাউনলোড</th>
                    </tr>
                    {
                        isLoading
                            ?
                            <Skli_law />
                            :
                            <>
                                {
                                    mainData.map((e, i) => {
                                        const { id, low_title, low_date, file } = e
                                        return (
                                            <>
                                                <tr key={id}>
                                                    <td className='text-left'>{low_title}</td>
                                                    <td className='text-center'>{low_date}</td>
                                                    <td className='text-center w-40'><a href={pdfURL + file} download={true} >ডাউনলোড</a></td>
                                                </tr>
                                            </>
                                        )
                                    })
                                }
                            </>
                    }
                </table>
            </div>
            {/* <div className="optionsPart">
                <div className="comment">
                    <img src={CommentImg} alt="" />
                </div>
            </div> */}
        </>
    )
}

export default Law