import logo from './logo.svg';
// Style files
import "../src/assets/scss/style.css"
import 'react-loading-skeleton/dist/skeleton.css'
import { Routes, Route, } from 'react-router-dom'
// Components
import Home from './pages/Home';
import Notice from './pages/Notice';
import News from './pages/news';
import History from './pages/History';
import Discribe from './pages/Discribe';
import NoticView from './pages/NoticView';
import OneView from './pages/OneView';
import Citigen from './pages/Citigen';
import Straps from './pages/Straps';
import Member from './pages/Member';
import VideoGy from './pages/VideoGy';
import DesitalServices from './pages/DesitalServices';
import PhotoGy from './pages/PhotoGy';
import Contact from './pages/Contact';
import Survices from './pages/Survices';
import NoSurvices from './pages/NoSurvices';
import Law from './pages/Law';
import Xexecutive from './pages/Xexecutive';
import Organugram from './pages/Organugram';
import Budget from './pages/Budget';
import DakBanglo from './pages/DakBanglo';
import WorkProcess from './pages/WorkProcess';
import ScrollToTop from './components/ScrollToTop';
import MainHeader from './components/MainHeader';
import Footer from './components/Footer';
import SideBar from './components/SideBar';

function App() {
  function Admin() {
    // 👇️ redirect to external URL
    window.location.replace('https://zprajshahi.com/zpr');

    return null;
  }
  return (
    <>
      <div className='contaier'>
        <MainHeader />
        <div className='mainContentPart'>
            {/* --------------------------------------- */}
            <ScrollToTop />
            <Routes basename="/build" >
              <Route path='/' exact element={<Home />} />
              <Route path='/admin' element={<Admin />} />
              <Route path='/notices' element={<Notice />} />
              <Route path='/notice/details/:id' element={<NoticView />} />
              <Route path='/news' element={<News />} />
              <Route path='/history' element={<History />} />
              <Route path='/letsView' element={<OneView />} />
              <Route path='/discribe/:id' element={<Discribe />} />
              <Route path='/citigen' element={<Citigen />} />
              <Route path='/law' element={<Law />} />
              <Route path='/budget' element={<Budget />} />
              <Route path='/dakbanglo' element={<DakBanglo />} />
              <Route path='/workprocess' element={<WorkProcess />} />
              <Route path='/kmkc' element={<Straps />} />
              <Route path='/member' element={<Member />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/desital-center' element={<DesitalServices />} />
              <Route path='/photo' element={<PhotoGy />} />
              <Route path='/video' element={<VideoGy />} />
              <Route path='/organugram' element={<Organugram />} />
              <Route path='/Xexecutive' element={<Xexecutive />} />
              <Route path='/Survices/:i' element={<Survices />} />
              <Route path='/NoSurvices' element={<NoSurvices />} />
              <Route path='/*' element={<Home />} />
            </Routes>
            {/* --------------------------------------- */}
          <div className='colam'>
            <SideBar />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
