import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import printImg from '../assets/img/print_btn.webp'
import MainAPI from '../assets/APIs/mainApi'
import { useState } from 'react'
import TabTittle from '../components/TabTittle'
import SideBar from '../components/SideBar'
import MainHeader from '../components/MainHeader'
import CommentImg from "../assets/img/comment.gif"
import ToploaderBar from '../components/ToploaderBar'

function News() {
    const [mainData, setMainData] = useState(MainAPI)
    TabTittle("খবর | জেলা পরিষদ, রাজশাহী।")
    return (
        <>
            <ToploaderBar />
            <div className='colam'>
                <div className="pageTittle">
                    <h2><Link to="/" >Hme</Link>/News</h2>
                </div>
                <div className="newcContent">
                    <div className="tittle"><h1>খবর</h1> <button title='print'><img src={printImg} alt="" /></button></div>
                    <table >
                        <tr className='header'>
                            <th>ক্রমিক</th>
                            <th>শিরোনাম</th>
                            <th>প্রকাশের তারিখ</th>
                        </tr>
                        {
                            mainData.notice.map((e, i) => {
                                const { tittle, date, id } = e
                                return (
                                    <>
                                        <tr>
                                            <td className='text-center'>{i + 1}</td>
                                            <td><Link to={`/news/details/${id}`}>{tittle}</Link></td>
                                            <td className='date text-center'>{date}</td>
                                        </tr>

                                    </>
                                )

                            })
                        }
                    </table>
                </div>
            </div>
            {/* <div className="optionsPart">
                <div className="comment">
                    <img src={CommentImg} alt="" />
                </div>
            </div> */}
        </>
    )
}

export default News
