import React from 'react'
import Footer from '../components/Footer'
// Images
import MainAPI from '../assets/APIs/mainApi'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import TabTittle from '../components/TabTittle'
import ToploaderBar from '../components/ToploaderBar'

function WorkProcess() {
    const [mainData, setMainData] = useState(MainAPI)
    TabTittle(`কার্যাবলী | ${mainData.ZPwork[0].tittle}`)
    return (
        <>
            <ToploaderBar />
            <div className='colam'>
                <div className="pageTittle">
                    <h2><Link to="/" >Hme</Link>/Work_Process</h2>
                </div>
                <div className="content history">
                    <h1 className='history-tittle'> ১। {mainData.ZPwork[0].tittle}</h1>

                    <div className="zpWork">
                        {
                            mainData.ZPwork.map((e) => {
                                const { tittle, tittle1, tittle2, para } = e
                                return (
                                    <>
                                        <div className="tittle1">
                                            <h2>{tittle1}</h2>
                                        </div>
                                        <div className="tittle2">
                                            <h3>{tittle2}</h3>
                                        </div>
                                        <div className="para">
                                            <p>{para}</p>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            {/* <div className="optionsPart">
                <div className="comment">
                    <img src={CommentImg} alt="" />
                </div>
            </div> */}
        </>
    )
}

export default WorkProcess
