import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
// Images
import { useState } from 'react'
import TabTittle from '../components/TabTittle'
import Iframe from 'react-iframe'
import axios from 'axios'
import Skeleton from 'react-loading-skeleton'
import ToploaderBar from '../components/ToploaderBar'

function Citigen() {
    TabTittle('সিটিজেন চার্টার | জেলা পরিষদ, রাজশাহী।')
    const imgUrl = `https://www.zprajshahi.gov.bd/zpr/assets/logo/`
    const CrtizenPdfUrl = 'https://www.zprajshahi.gov.bd/zpr/assets/citizen/'
    const API = `https://www.zprajshahi.gov.bd/zpr/data/citizen`
    const [mainData, setmainData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const getAPIData = async (api) => {
        try {
            const res = await axios.get(api)
            const Data = await res.data
            setmainData(Data)
            setIsLoading(false)
        } catch (error) {
            console.log(error.message);
        }
    }
    useEffect(() => {
        getAPIData(API)
    }, []);
    return (
        <>
        <ToploaderBar/>
            <div className='colam'>
                <div className="pageTittle">
                    <h2><Link to="/" >Hme</Link>/Citizen</h2>
                </div>
                <div className="newcContent">
                    <div className="tittle"><h1>সিটিজেন চার্টার</h1></div>
                    <div className="headerCitigen">
                        <div className="row">
                            <div className="logo">
                                <img src={`${imgUrl}logo.png`} alt="" />
                            </div>
                            <div className="centerPart text-center">
                                <h1>জেলা পরিষদ</h1>
                                <p>রাজশাহী</p>
                                <a href="www.zprajshahi.gov.bd" rel="noopener noreferrer">www.zprajshahi.gov.bd</a>
                            </div>
                            <div className="rightPart text-center">
                                <h3>উন্নয়নের গনতন্ত্র</h3>
                                <h3>শেখ হাসিনার মূলমন্ত্র</h3>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="content">
                        {
                            isLoading
                                ?
                                <Skeleton width={'100%'} baseColor="black" highlightColor='#121212' height={'1000px'} />
                                :
                                <>
                                    <Iframe url={CrtizenPdfUrl + mainData[0].file} width='100%' height='900' />
                                </>
                        }
                    </div>
                </div>
            </div>
            {/* <div className="optionsPart">
                <div className="comment">
                    <img src={CommentImg} alt="" />
                </div>
            </div> */}
        </>
    )
}

export default Citigen
