import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import TabTittle from '../components/TabTittle'
import ToploaderBar from '../components/ToploaderBar'
import MainAPI from '../assets/APIs/mainApi'


function Survices() {
    TabTittle("জেলা পরিষদ, রাজশাহী।")
    const { i } = useParams()
    const UpdateData = MainAPI.ServInfo.filter((e, index) => {
        return index == i
    })
    console.log(UpdateData);
    return (
        <>
            <ToploaderBar />
            <div className='colam'>
                <h1>{UpdateData[0].list[0].tittle} এর জন্য কোন তথ্য নেই!</h1>
            </div>
        </>
    )
}

export default Survices