import axios from "axios"
import { useReducer, useEffect, useContext, createContext } from "react"

// files================
import StrafReducer from "../reducer/StrafReducer"
// =======================
const AppContext = createContext()

const AppProvider = ({ children }) => {


    // Get All API data for Strafs
    const intialState = {
        isLoading: false,
        isSingleLoading: false,
        isErroe: false,
        isSingleErroe: false,
        strafs: [],
        singleStrap: {}
    }
    const [state, dispatch] = useReducer(StrafReducer, intialState);


    const API = `https://zpr.s3cbd.com/data/staff/`
    // ===================All Straf Data ======================
    const getApi_Data = async (api) => {
        dispatch({type: "SET_LOADING"})
        try {
            const res = await axios.get(api)
            const Data = await res.data
            dispatch({type: "SET_API_DATA", payload: Data})
        } catch (error) {
            dispatch({type:"API_ERROR"})
        }
    }
    useEffect(() => {
        getApi_Data(API)
    }, [])
    // ========================================================
    // ===================Single Straf Data ===================
    const singleStrafData = async (api) =>{
        dispatch({type: "SET_SINGLE_LOADING"})
        try {
            const res = await axios.get(api)
            const singleData = res.data
            dispatch({type: "SET_SINGLE_STRAF", payload: singleData})
        } catch (error) {
            dispatch({type: "SET_SINGLE_ERROR"})
            
        }
    }




    return <AppContext.Provider value={{ ...state, getApi_Data ,singleStrafData }} >{children}</AppContext.Provider>
}

const useStrafContext = () => {
    return useContext(AppContext)
}

export { AppProvider, AppContext, useStrafContext }