import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import SkliNoticeBox from './skeletonComp/Skli_NoticeBox'

function NoticeBoard({ name }) {
  const pdfURL = `https://www.zprajshahi.gov.bd/zpr/assets/zprnotice/`
  const API = `https://www.zprajshahi.gov.bd/zpr/data/notice`
  const [mainData, setmainData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const getAPIData = async (api) => {
    try {
      const res = await axios.get(api)
      const Data = await res.data
      setmainData(Data)
      setIsLoading(false)
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getAPIData(API)
  }, []);
  return (
    <>
      <div className="notice-board-bg">
        <h2>নোটিশ বোর্ড</h2>
        {
          isLoading
            ?
            <SkliNoticeBox />
            :
            <>
              <div className="notice-board-ticker">
                <ul className='styleList'>
                  {
                    mainData.splice(0, 5).map((e, i) => {
                      const { title, id } = e
                      return (
                        <>
                          <li>
                            <Link to={`notice/details/${id}`}>{title}</Link> <span>New</span>
                          </li>
                        </>
                      )

                    })
                  }
                </ul>
                <Link class="btn right" to="/notices" title="সকল নোটিশ">সকল</Link>
              </div>
            </>
        }
      </div>
    </>
  )
}

export default NoticeBoard
