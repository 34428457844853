import React from 'react'
import Skeleton from 'react-loading-skeleton'

function SkliStaff() {
    return (
        <>
            <tr>
                <td className='text-center'><Skeleton/></td>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
                <td className='text-center'><Skeleton/></td>
                <td className='text-center'><Skeleton width={'50px'} height={'50px'} /></td>
            </tr>
            <tr>
                <td className='text-center'><Skeleton/></td>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
                <td className='text-center'><Skeleton/></td>
                <td className='text-center'><Skeleton width={'50px'} height={'50px'} /></td>
            </tr>
            <tr>
                <td className='text-center'><Skeleton/></td>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
                <td className='text-center'><Skeleton/></td>
                <td className='text-center'><Skeleton width={'50px'} height={'50px'} /></td>
            </tr>
            <tr>
                <td className='text-center'><Skeleton/></td>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
                <td className='text-center'><Skeleton/></td>
                <td className='text-center'><Skeleton width={'50px'} height={'50px'} /></td>
            </tr>
            <tr>
                <td className='text-center'><Skeleton/></td>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
                <td className='text-center'><Skeleton/></td>
                <td className='text-center'><Skeleton width={'50px'} height={'50px'} /></td>
            </tr>
            <tr>
                <td className='text-center'><Skeleton/></td>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
                <td className='text-center'><Skeleton/></td>
                <td className='text-center'><Skeleton width={'50px'} height={'50px'} /></td>
            </tr>
            <tr>
                <td className='text-center'><Skeleton/></td>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
                <td className='text-center'><Skeleton/></td>
                <td className='text-center'><Skeleton width={'50px'} height={'50px'} /></td>
            </tr>
        </>
    )
}

export default SkliStaff