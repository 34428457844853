import React from 'react'
import Skeleton from 'react-loading-skeleton'

function Skli_Notice() {
    return (
        <>
            <tr>
                <td className='text-center'><Skeleton width={'25px'} /></td>
                <td><Skeleton /></td>
                <td className='date text-center'><Skeleton /></td>
                <td className='text-center download'><Skeleton /></td>
            </tr>
            <tr>
                <td className='text-center'><Skeleton width={'25px'} /></td>
                <td><Skeleton /></td>
                <td className='date text-center'><Skeleton /></td>
                <td className='text-center download'><Skeleton /></td>
            </tr>
            <tr>
                <td className='text-center'><Skeleton width={'25px'} /></td>
                <td><Skeleton /></td>
                <td className='date text-center'><Skeleton /></td>
                <td className='text-center download'><Skeleton /></td>
            </tr>
            <tr>
                <td className='text-center'><Skeleton width={'25px'} /></td>
                <td><Skeleton /></td>
                <td className='date text-center'><Skeleton /></td>
                <td className='text-center download'><Skeleton /></td>
            </tr>
            <tr>
                <td className='text-center'><Skeleton width={'25px'} /></td>
                <td><Skeleton /></td>
                <td className='date text-center'><Skeleton /></td>
                <td className='text-center download'><Skeleton /></td>
            </tr>
            <tr>
                <td className='text-center'><Skeleton width={'25px'} /></td>
                <td><Skeleton /></td>
                <td className='date text-center'><Skeleton /></td>
                <td className='text-center download'><Skeleton /></td>
            </tr>
            <tr>
                <td className='text-center'><Skeleton width={'25px'} /></td>
                <td><Skeleton /></td>
                <td className='date text-center'><Skeleton /></td>
                <td className='text-center download'><Skeleton /></td>
            </tr>
            <tr>
                <td className='text-center'><Skeleton width={'25px'} /></td>
                <td><Skeleton /></td>
                <td className='date text-center'><Skeleton /></td>
                <td className='text-center download'><Skeleton /></td>
            </tr>
            <tr>
                <td className='text-center'><Skeleton width={'25px'} /></td>
                <td><Skeleton /></td>
                <td className='date text-center'><Skeleton /></td>
                <td className='text-center download'><Skeleton /></td>
            </tr>
            <tr>
                <td className='text-center'><Skeleton width={'25px'} /></td>
                <td><Skeleton /></td>
                <td className='date text-center'><Skeleton /></td>
                <td className='text-center download'><Skeleton /></td>
            </tr>
            <tr>
                <td className='text-center'><Skeleton width={'25px'} /></td>
                <td><Skeleton /></td>
                <td className='date text-center'><Skeleton /></td>
                <td className='text-center download'><Skeleton /></td>
            </tr>
            <tr>
                <td className='text-center'><Skeleton width={'25px'} /></td>
                <td><Skeleton /></td>
                <td className='date text-center'><Skeleton /></td>
                <td className='text-center download'><Skeleton /></td>
            </tr>
            <tr>
                <td className='text-center'><Skeleton width={'25px'} /></td>
                <td><Skeleton /></td>
                <td className='date text-center'><Skeleton /></td>
                <td className='text-center download'><Skeleton /></td>
            </tr>
            <tr>
                <td className='text-center'><Skeleton width={'25px'} /></td>
                <td><Skeleton /></td>
                <td className='date text-center'><Skeleton /></td>
                <td className='text-center download'><Skeleton /></td>
            </tr>
            <tr>
                <td className='text-center'><Skeleton width={'25px'} /></td>
                <td><Skeleton /></td>
                <td className='date text-center'><Skeleton /></td>
                <td className='text-center download'><Skeleton /></td>
            </tr>
        </>
    )
}

export default Skli_Notice