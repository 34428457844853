import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import LoadingBar from 'react-top-loading-bar'

function ToploaderBar() {
    const [loadingProgress, setLoadingProgress] = useState(0);
    useEffect(() => {
        window.addEventListener('load', () => {
            setLoadingProgress(800);
        });
    }, []);

    useEffect(() => {
        setLoadingProgress(100);
    }, []);
    return (
        <>
            <LoadingBar
                progress={loadingProgress}
                height={3}
                color='#60ff00'
            />
        </>
    )
}

export default ToploaderBar