import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import homeImg from '../assets/img/home_dark.png'
import LanguageSelector from './LanguageSelector'

function NavBar() {
    
    const toggleBTN = () => {
        const nav = document.querySelector('.mainHeader nav')
        nav.classList.toggle('active')
    }
    return (
        <>
            <div className="mainHeader">
                <nav >
                    <div className="toggle_btn">
                        <button id='toggleBTN' onClick={toggleBTN}>|||</button>
                    </div>
                    <ul>
                        <li>
                            <Link to="/">
                                <img src={homeImg} alt="" />
                            </Link>
                        </li>
                        <li className='orange'>আমাদের সম্পর্কে
                            <div className="subMenu">
                                <ul>
                                    <li><Link to="/history">জেলা পরিষদের ইতিহাস</Link></li>
                                    <li><Link to="/workprocess">জেলা পরিষদের কার্যাবলী</Link></li>
                                    <li><Link to="/letsView">এক নজরে জেলা পরিষদ</Link></li>
                                    <li><Link to="/law">আইন বিধিমালা</Link></li>
                                    <li><Link to="/citigen">সিটিজেন চার্টার</Link></li>
                                    <li><Link to="/Xexecutive">পূর্বতন প্রধান নির্বাহী কর্মকর্তা</Link></li>
                                </ul>
                            </div>
                        </li>
                        <li className='red'><NavLink to="/notices">নোটিস</NavLink></li>
                        <li className='green'><NavLink to="/member">পরিষদ</NavLink></li>
                        <li className='tomato'><NavLink to="/kmkc">কর্মকর্তা ও কর্মচারীর তথ্য</NavLink></li>
                        <li className='green'>প্রকল্পসমূহ
                            <div className="subMenu">
                                <ul>
                                    <li><Link to="#">অগ্রগতির বিবরণ</Link></li>
                                    <li><Link to="#">চলমান প্রকল্পসমূহ</Link></li>
                                    <li><Link to="#">পূর্ববর্তী প্রকল্পসমূহ</Link></li>
                                </ul>
                            </div>
                        </li>
                        <li className='red'>গ্যালারী
                            <div className="subMenu">
                                <ul>
                                    <li><Link to="/photo">ফটো গ্যালারী</Link></li>
                                    <li><Link to="/video">ভিডিও গ্যালারী</Link></li>
                                </ul>
                            </div>
                        </li>
                        <li className='slateblue'><Link to="/contact">যোগাযোগ</Link></li>
                        <li className='orange'>ই-সেবা
                            <div className="subMenu">
                                <ul>
                                    <li><Link to="/desital-center">ডিজিটাল সেন্টার</Link></li>
                                </ul>
                            </div>
                        </li>
                        <li className='red active'><a href="https://zprajshahi.gov.bd/zpr/apply" target="_blank" rel="noopener noreferrer">শিক্ষাবৃত্তি</a></li>
                        <li className='teal end'>অন্যান্য
                            <div className="subMenu">
                                <ul>
                                    <li><Link to="/organugram">জেলা পরিষদ অর্গানোগ্রাম</Link></li>
                                    <li><Link to="/budget">বাজেট</Link></li>
                                    <li><Link to="/dakbanglo">ডাকবাংলো</Link></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    )
}

export default NavBar
