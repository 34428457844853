import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AppProvider } from './context/AppContext';

const list = [
  {
    tittle: "Fontend-Developer",
    name: "Md.Maruf Suborno",
    contact: "+8801885685632",
  },
  {
    tittle: "Backend-Developer",
    name: "Golam Kibriya",
    contact: "+8801724775298",
  },
]

console.table(`%c Zela Porisod Rajshai`,`color: gold; font-size: 35px; margin: 0 auto`);
console.table(list)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AppProvider>
      <App />
    </AppProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
