import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import printImg from '../assets/img/print_btn.webp'
import MainAPI from '../assets/APIs/mainApi'
import { useState } from 'react'
import TabTittle from '../components/TabTittle'
import SideBar from '../components/SideBar'
import MainHeader from '../components/MainHeader'
import CommentImg from "../assets/img/comment.gif"
import LoadingBar from 'react-top-loading-bar'
import { useEffect } from 'react'
import axios from 'axios'
import SkliBudget from '../components/skeletonComp/Skli_Budget'
import ToploaderBar from '../components/ToploaderBar'
const pdfURL = `https://www.zprajshahi.gov.bd/zpr/assets/zprbudgets/`


function Budget() {
    TabTittle('বাজেট | জেলা পরিষদ, রাজশাহী।')
    const API = `https://www.zprajshahi.gov.bd/zpr/data/budget`
    const [mainData, setmainData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const getAPIData = async (api) => {
        try {
            const res = await axios.get(api)
            const Data = await res.data
            setmainData(Data)
            setIsLoading(false)
        } catch (error) {
            console.log(error.message);
        }
    }
    useEffect(() => {
        getAPIData(API)
    }, []);

    

    return (
        <>
            <ToploaderBar/>
            <div className='colam'>
                <div className="pageTittle">
                    <h2><Link to="/" >Hme</Link>/Budget</h2>
                </div>
                <div className="newcContent">
                    <div className="tittle"><h1>বাজেট</h1> <button title='print'><img src={printImg} alt="" /></button></div>
                    <div className="scroll-x">
                        <table >
                            <tr className='header'>
                                <th>বিস্তারিত</th>
                                <th>তারিখ</th>
                                <th>ডাউনলোড</th>
                            </tr>
                            {
                                isLoading
                                    ?
                                    <SkliBudget />
                                    :
                                    <>
                                        {
                                            mainData.map(e => {
                                                const { b_title, created_at, file } = e
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>{b_title}</td>
                                                            <td>{created_at}</td>
                                                            <td><a href={pdfURL + file} download={true} >ডাউনলোড</a></td>
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }
                                    </>
                            }

                        </table>
                    </div>

                </div>
            </div>
            {/* <div className="optionsPart">
                <div className="comment">
                    <img src={CommentImg} alt="" />
                </div>
            </div> */}
        </>
    )
}

export default Budget
