import React from 'react'
import Footer from '../components/Footer'
// Images
import MainAPI from '../assets/APIs/mainApi'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import img1 from "../assets/img/2.webp"
import TabTittle from '../components/TabTittle'
import ToploaderBar from '../components/ToploaderBar'

function History() {
    const [mainData, setMainData] = useState(MainAPI)
    TabTittle(`ইতিহাস | ${mainData.history[0].tittle}`)
    return (
        <>
            <ToploaderBar />
            <div className='colam'>
                <div className="pageTittle">
                    <h2><Link to="/" >Hme</Link>/History</h2>
                </div>
                <div className="content history">
                    <h1 className='history-tittle'> ১। {mainData.history[0].tittle}</h1>
                    <p>{mainData.history[0].para1}</p>
                    <br />
                    <img src={img1} alt="" className="full_img" />
                    <br />
                    <br />
                    <p>{mainData.history[0].para2}</p>
                    <br />
                    <p>{mainData.history[0].para3}</p>
                    <br />
                    <p>{mainData.history[0].para4}</p>
                    <br />
                    <p>{mainData.history[0].para5}</p>
                    <br />
                    <p>{mainData.history[0].para6}</p>
                    <br />
                    <p>{mainData.history[0].para7}</p>

                </div>
            </div>
            {/* <div className="optionsPart">
                <div className="comment">
                    <img src={CommentImg} alt="" />
                </div>
            </div> */}
        </>
    )
}

export default History
