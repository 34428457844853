import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import printImg from '../assets/img/print_btn.webp'
import { useState } from 'react'
import TabTittle from '../components/TabTittle'
import { useEffect } from 'react'
import axios from 'axios'
import Skli_Notice from '../components/skeletonComp/Skli_Notice'
import ToploaderBar from '../components/ToploaderBar'

function Xexecutive() {
    TabTittle('রাজশাহী জেলা পরিষদে প্রধান নির্বাহী কর্মকর্তা হিসাবে দায়িত্ব পালনকারী... | জেলা পরিষদ, রাজশাহী।')
    const API = `https://www.zprajshahi.gov.bd/zpr/data/xexecutive`
    const [mainData, setmainData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const getAPIData = async (api) => {
        try {
            const res = await axios.get(api)
            const Data = await res.data
            setmainData(Data)
            setIsLoading(false)
        } catch (error) {
            console.log(error.message);
        }
    }
    useEffect(() => {
        getAPIData(API)
    }, []);

    return (
        <>
            <ToploaderBar />
            <div className='colam'>
                <div className="pageTittle">
                    <h2><Link to="/" >Hme</Link>/X-executive</h2>
                </div>
                <div className="newcContent">
                    <div className="tittle"><h1>রাজশাহী জেলা পরিষদে প্রধান নির্বাহী কর্মকর্তা হিসাবে দায়িত্ব পালনকারী বাংলাদেশ সরকারের উপসচিবগণের নাম ও কার্যকাল</h1> <button title='print'><img src={printImg} alt="" /></button></div>
                    <div className="scroll-x">
                        <table >
                            <tr className='header'>
                                <th>ক্রমিক</th>
                                <th>নাম</th>
                                <th colSpan={2}>কার্যকাল</th>
                            </tr>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>হইতে</th>
                                <th>পর্যন্ত</th>
                            </tr>
                            {
                                isLoading
                                    ?
                                    <Skli_Notice />
                                    :
                                    <>
                                        {
                                            mainData.map((e, i) => {
                                                const { title, name, id, file, joining_date, release_date } = e
                                                return (
                                                    <>
                                                        <tr>
                                                            <td className='text-center'>{i + 1}</td>
                                                            <td><Link to={`/notice/details/${id}`}>{name}</Link></td>
                                                            <td className='date text-center'>{joining_date}</td>
                                                            <td className='date text-center'>{release_date}</td>
                                                        </tr>

                                                    </>
                                                )

                                            })
                                        }
                                    </>
                            }
                        </table>
                    </div>

                </div>
            </div>
            {/* <div className="optionsPart">
                <div className="comment">
                    <img src={CommentImg} alt="" />
                </div>
            </div> */}
        </>
    )
}

export default Xexecutive
