import React from 'react'
import Skeleton from 'react-loading-skeleton'

function SkliBudget() {
    return (
        <>
            <tr>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
            </tr>
            <tr>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
            </tr>
            <tr>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
            </tr>
            <tr>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
            </tr>
            <tr>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
            </tr>
            <tr>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
            </tr>
            <tr>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
            </tr>
            <tr>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
            </tr>
            <tr>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
            </tr>
            <tr>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
                <td><Skeleton/></td>
            </tr>
        </>
    )
}

export default SkliBudget