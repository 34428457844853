import React, { useEffect } from 'react'
import { useState } from 'react'
import MemberCard from '../components/MemberCard'
import AudioComp from '../components/AudioComp'
import EsLinks from '../components/EsLinks'
import MainAPI from '../assets/APIs/mainApi'
import SideImg from './SideImg'
// Images
import img_1 from "../assets/img/National-Helpline.jpg"
import img_2 from "../assets/img/mask-bd-portal-.jpg"
import axios from 'axios'
import Skli_MemberCard from './skeletonComp/Skli_Member'
import { SkeletonTheme } from 'react-loading-skeleton'
import Skli_EsLinks from './skeletonComp/Skli_EsList'
const imgURL = `https://www.zprajshahi.gov.bd/zpr/assets/zprstaff/`
function SideBar() {
    const API = `https://www.zprajshahi.gov.bd/zpr/data/staff`
    const [staff, setStaff] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    
    const getAPIData = async (api) => {
        try {
            const res = await axios.get(api)
            const Data = await res.data
            setStaff(Data)
            setIsLoading(false)
        } catch (error) {
            console.log(error.message);
        }
    }
    useEffect(() => {
        getAPIData(API)
    }, []);


    return (
        <>
            <div className="sideBar">
                <SkeletonTheme>
                    {
                        isLoading
                            ?
                            <div>
                                <Skli_MemberCard />
                                <br />
                                <Skli_MemberCard />
                                <br />
                                <Skli_EsLinks />
                                <br />
                            </div>
                            :
                            <div>
                                <div className="memberBox">
                                <MemberCard id={staff[0].id} tittle={staff[0].designation} img={imgURL + staff[0].image} reg={staff[0].name} alt={staff[0].name} />
                                <MemberCard id={staff[1].id} tittle={staff[1].designation} img={imgURL + staff[1].image} reg={staff[1].name} alt={staff[1].name} />
                                </div>
                            </div>
                    }
                    <AudioComp tittle={'জাতীয় সংগীত'} />
                    <br />
                    <EsLinks />
                    <br />
                    <SideImg tittle={'জরুরি হটলাইন'} img={img_1} alt={'জরুরি হটলাইন'} />
                    <br />
                </SkeletonTheme>
            </div>
        </>
    )
}

export default SideBar
