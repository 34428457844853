import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import printImg from '../assets/img/print_btn.webp'
import { useState } from 'react'
import TabTittle from '../components/TabTittle'
import { useEffect } from 'react'
import axios from 'axios'
import Skli_Notice from '../components/skeletonComp/Skli_Notice'
import ToploaderBar from '../components/ToploaderBar'
import CopyToClipboard from '../components/copyToClipboard'



const pdfURL = `https://www.zprajshahi.gov.bd/zpr/assets/zprnotice/`

function Notice() {
    TabTittle('নোটিশ | জেলা পরিষদ, রাজশাহী।')
    const API = `https://www.zprajshahi.gov.bd/zpr/data/notice`
    const [mainData, setmainData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const getAPIData = async (api) => {
        try {
            const res = await axios.get(api)
            const Data = await res.data
            setmainData(Data)
            setIsLoading(false)
        } catch (error) {
            console.log(error.message);
        }
    }
    useEffect(() => {
        getAPIData(API)
    }, []);
    // ====================
    const downloadFile = async (pdfurl) => {
        const response = await fetch(pdfurl);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
    };

    // ====================
    return (
        <>
        <ToploaderBar/>
            <div className='colam'>
                <div className="pageTittle">
                    <h2><Link to="/" >Hme</Link>/Notice</h2>
                </div>
                <div className="newcContent">
                    <div className="tittle"><h1>নোটিশ</h1> <button title='print'><img src={printImg} alt="" /></button></div>
                    <div className="scroll-x">
                        <table >
                            <tr className='header'>
                                <th>ক্রমিক</th>
                                <th>শিরোনাম</th>
                                <th>প্রকাশের তারিখ</th>
                                <th>ডাউনলোড</th>
                                <th>Copy Link</th>
                            </tr>
                            {
                                isLoading
                                    ?
                                    <Skli_Notice />
                                    :
                                    <>
                                        {
                                            mainData.map((e, i) => {
                                                const { title, notice_date, id, file } = e
                                                return (
                                                    <>
                                                        <tr id={id}>
                                                            <td className='text-center'>{i + 1}</td>
                                                            <td><Link to={`/notice/details/${id}`}>{title}</Link></td>
                                                            <td className='date text-center'>{notice_date}</td>
                                                            <td className='text-center download'><a download={true} onClick={() => downloadFile(`${pdfURL}${file}`)} href={`${pdfURL}${file}`} >ডাউনলোড</a></td>
                                                            <td className='text-center'><CopyToClipboard text={`${pdfURL}${file}`} /></td>
                                                        </tr>

                                                    </>
                                                )

                                            })
                                        }
                                    </>
                            }
                        </table>
                    </div>

                </div>
            </div>
            {/* <div className="optionsPart">
                <div className="comment">
                    <img src={CommentImg} alt="" />
                </div>
            </div> */}
        </>
    )
}

export default Notice
