import React from 'react'
import Skeleton from 'react-loading-skeleton'

function Skli_Dakbanglo() {
    return (
        <>
            <tr>
                <td><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
            </tr>
            <tr>
                <td><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
            </tr>
            <tr>
                <td><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
            </tr>
            <tr>
                <td><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
            </tr>
            <tr>
                <td><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
            </tr>
            <tr>
                <td><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
            </tr>
            <tr>
                <td><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
            </tr>
            <tr>
                <td><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
            </tr>
            <tr>
                <td><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
            </tr>
            <tr>
                <td><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
            </tr>
            <tr>
                <td><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
            </tr>
            <tr>
                <td><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
            </tr>
            <tr>
                <td><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
                <td className='text-center' ><Skeleton /></td>
            </tr>
        </>
    )
}

export default Skli_Dakbanglo