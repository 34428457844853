import React from 'react'
import TopHeader from '../components/TopHeader'
import Slider from '../components/Slider'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import MainAPI from '../assets/APIs/mainApi'
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import TabTittle from '../components/TabTittle'
import Iframe from 'react-iframe'
import SideBar from '../components/SideBar'
import MainHeader from '../components/MainHeader'
import CommentImg from "../assets/img/comment.gif"
import axios from 'axios'
import { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import ToploaderBar from '../components/ToploaderBar'

function NoticView() {
    const { id } = useParams()
    const pdfURL = `https://www.zprajshahi.gov.bd/zpr/assets/zprnotice/`
    const API = `https://www.zprajshahi.gov.bd/zpr/data/notice`
    const [mainData, setmainData] = useState([])
    const updateData = mainData.filter((e) => {
        return e.id == id
    })
    TabTittle(`নোটিশ | জেলা পরিষদ, রাজশাহী।`)
    const [isLoading, setIsLoading] = useState(true)
    const getAPIData = async (api) => {
        try {
            const res = await axios.get(api)
            const Data = await res.data
            setmainData(Data)
            setIsLoading(false)
        } catch (error) {
            console.log(error.message);
        }
    }
    useEffect(() => {
        getAPIData(API)
    }, []);

    return (
        <>
            <ToploaderBar />
            <div className='colam'>
                <div className="pageTittle">
                    <h2><Link to="/" >Hme</Link>/<Link to="/notice" >Notice</Link>/Details</h2>
                </div>
                {
                    isLoading
                        ?
                        <>
                            <div className="tittle">
                                <h1><Skeleton /></h1>
                            </div>
                            <div className="content">
                                <Skeleton width={'100%'} baseColor="black" highlightColor='#121212' height={'1000px'} />
                            </div>

                        </>
                        :
                        <>
                            <div className="tittle">
                                <h1>{updateData[0].title}</h1>
                            </div>
                            <div className="content">
                                <Iframe url={pdfURL + updateData[0].file} width='100%' id='iframe' height='900' />
                            </div>
                        </>
                }

            </div>
            {/* <div className="optionsPart">
                <div className="comment">
                    <img src={CommentImg} alt="" />
                </div>
            </div> */}
        </>
    )
}

export default NoticView
