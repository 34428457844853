import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
// Images
import printImg from '../assets/img/print_btn.png'
import MainAPI from '../assets/APIs/mainApi'
import { useState } from 'react'
import TabTittle from '../components/TabTittle'
import SideBar from '../components/SideBar'
import MainHeader from '../components/MainHeader'
import CommentImg from "../assets/img/comment.gif"
import Iframe from 'react-iframe'
import axios from 'axios'
import Skeleton from 'react-loading-skeleton'
import ToploaderBar from '../components/ToploaderBar'

function Organugram() {
    TabTittle('জেলা পরিষদ অর্গানোগ্রাম | জেলা পরিষদ, রাজশাহী।')
    const imgUrl = `https://www.zprajshahi.gov.bd/zpr/assets/logo/`
    const CrtizenPdfUrl = 'https://www.zprajshahi.gov.bd/zpr/assets/organogram/'
    const API = `https://www.zprajshahi.gov.bd/zpr/data/organogram`
    const [mainData, setmainData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const getAPIData = async (api) => {
        try {
            const res = await axios.get(api)
            const Data = await res.data
            setmainData(Data)
            setIsLoading(false)
        } catch (error) {
            console.log(error.message);
        }
    }
    useEffect(() => {
        getAPIData(API)
    }, []);
    return (
        <>
            <ToploaderBar />
            <div className='colam'>
                <div className="pageTittle">
                    <h2><Link to="/" >Hme</Link>/Organugram</h2>
                </div>
                <div className="newcContent">
                    <div className="tittle"><h1>জেলা পরিষদ অর্গানোগ্রাম</h1></div>
                    <div className="content">
                        {
                            isLoading
                                ?
                                <Skeleton width={'100%'} baseColor="black" highlightColor='#121212' height={'1000px'} />
                                :
                                <>
                                    <Iframe url={CrtizenPdfUrl + mainData[0].file} width='100%' height='900' />
                                </>
                        }
                    </div>
                </div>
            </div>
            {/* <div className="optionsPart">
                <div className="comment">
                    <img src={CommentImg} alt="" />
                </div>
            </div> */}
        </>
    )
}

export default Organugram
