import React from 'react'
import Skeleton from 'react-loading-skeleton'

function Skli_NewsShot() {
    return (
        <>
            <li> <Skeleton count={2} /></li>
            <li> <Skeleton count={2} /></li>
            <li> <Skeleton count={2} /></li>
            <li> <Skeleton count={2} /></li>
            <li> <Skeleton count={2} /></li>
            <li> <Skeleton count={2} /></li>
            <li> <Skeleton count={2} /></li>
            <li> <Skeleton count={2} /></li>
            <li> <Skeleton count={2} /></li>
            <li> <Skeleton count={2} /></li>
        </>
    )
}

export default Skli_NewsShot
