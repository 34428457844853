import React from 'react'
import MainAPI from '../assets/APIs/mainApi'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import TabTittle from '../components/TabTittle'
import ToploaderBar from '../components/ToploaderBar'
function OneView() {
    TabTittle("এক নজরে জেলা পরিষদ")
    const [mainData, setMainData] = useState(MainAPI)
    return (
        <>
            <ToploaderBar />
            <div className="colam">
                <div className="pageTittle">
                    <h2><Link to="/" >Hme</Link>/View</h2>
                </div>
                <div className="tittle">
                    <h1>এক নজরে জেলা পরিষদ</h1>
                </div>
                <table className='table'>
                    {
                        mainData.oneView.map((e, i) => {
                            const { name, value } = e
                            return (
                                <>
                                    <tr>
                                        <td className='text-center'>{i + 1}</td>
                                        <td className='text-center'>{name}</td>
                                        <td className='text-center w-40'>{value}</td>
                                    </tr>
                                </>
                            )
                        })
                    }
                </table>
            </div>
            {/* <div className="optionsPart">
                <div className="comment">
                    <img src={CommentImg} alt="" />
                </div>
            </div> */}
        </>
    )
}

export default OneView