import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <>
            <div class="footer-artwork" id="footer-artwork">&nbsp;</div>
            <div class="footer-wrapper full-width" id="footer-wrapper">
                <div className="footerMenu">
                    <ul>
                        <li><a href="https://goo.gl/maps/Rto1keNWSrQqfHvw9" target="_blank" rel="noopener noreferrer">ম্যাপ</a></li>
                        <li><Link to="/photo" >ফটো গ্যালারী</Link></li>
                        <li><Link to="/video" >ভিডিও গ্যালারী</Link></li>
                    </ul>
                    <div className="LastUpdate">
                        <p>Last Update: 06 Apr 2023</p>
                    </div>
                </div>
                <div className="suporters text-right">
                    <ul>
                        <li>পরিকল্পনা ও বাস্তবায়নে:</li>
                        <li><a href="http://www.cabinet.gov.bd/" target="_blank" rel="noopener noreferrer">মন্ত্রিপরিষদ
                            বিভাগ</a></li>
                        <li><a href="https://a2i.gov.bd/" target="_blank" rel="noopener noreferrer">এটুআই</a></li>
                        <li><a href="http://www.bcc.net.bd/" target="_blank" rel="noopener noreferrer">বিসিসি</a></li>
                        <li><a href="http://doict.gov.bd/" target="_blank" rel="noopener noreferrer">ডিওআইসিটি</a></li>
                        <li><a href="http://www.basis.org.bd/" target="_blank" rel="noopener noreferrer">বেসিস।</a></li>
                    </ul>
                    <div className="developers">
                        <p><b>@Web Developers: <a href="https://www.facebook.com/suborn0.dev" target="_blank" rel="noopener noreferrer">Suborno180</a>, <a href="https://www.facebook.com/kibriacse" target="_blank" rel="noopener noreferrer">Golam Kibriya</a></b></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer