import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MainAPI from '../assets/APIs/mainApi'

function CardInfo({ tittle, img, list }) {
    const [manData, setMainData] = useState(MainAPI)
    return (
        <>
        <div className="infoCard">
                                <div className="tittle">
                                    <h2>সামাজিক কাজ</h2>
                                </div>
                                <div className="imgText">
                                    <img src={'/imgs/seba.jpg'} alt="image" />
                                    <ul className='styleList'>
                                        <li><a href="https://zprajshahi.gov.bd/zpr/apply" target="_blank" rel="noopener noreferrer">শিক্ষাবৃত্তি</a></li>
                                        <li><Link to="/NoSurvices" >আর্থিক সহযোগিতা</Link></li>
                                        <li><Link to="/NoSurvices" >প্রশিক্ষণ</Link></li>
                                    </ul>
                                </div>
                            </div>
            {
                manData.ServInfo.map((e,i) => {
                    const { tittle, logo, list, path } = e
                    return (
                        <>
                            <div className="infoCard">
                                <div className="tittle">
                                    <h2>{tittle}</h2>
                                </div>
                                <div className="imgText">
                                    <img src={logo} alt="" />
                                    <ul className='styleList'>
                                        {
                                            list.map(e => {
                                                const {tittle, path} = e
                                                return (
                                                    <>
                                                        <li><Link to={`${path}/${i}`} >{tittle}</Link></li>
                                                    </>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </>
                    )
                })
            }

        </>
    )
}

export default CardInfo