import React from 'react'
import Skeleton from 'react-loading-skeleton'

function Skli_EsLinks() {
    return (
        <>
            <div className="esLinks">
                <div className="card">
                    <div className="tittle">
                        <Skeleton  baseColor="#4ba614" />
                    </div>
                    <ul className='styleList'>
                        <li><Skeleton/></li>
                        <li><Skeleton/></li>
                        <li><Skeleton/></li>
                        <li><Skeleton/></li>
                        <li><Skeleton/></li>
                        <li><Skeleton/></li>
                        <li><Skeleton/></li>
                        <li><Skeleton/></li>
                        <li><Skeleton/></li>
                        <li><Skeleton/></li>
                        <li><Skeleton/></li>
                        <li><Skeleton/></li>
                        <li><Skeleton/></li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Skli_EsLinks
