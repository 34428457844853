import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import printImg from '../assets/img/print_btn.webp'
import MainAPI from '../assets/APIs/mainApi'
import { useState } from 'react'
import TabTittle from '../components/TabTittle'
import SideBar from '../components/SideBar'
import MainHeader from '../components/MainHeader'
import CommentImg from "../assets/img/comment.gif"
import LoadingBar from 'react-top-loading-bar'
import { useEffect } from 'react'
import axios from 'axios'
import ToploaderBar from '../components/ToploaderBar'
import Skli_Dakbanglo from '../components/skeletonComp/Skli_Dakbanglo'
const pdfURL = `https://www.zprajshahi.gov.bd/zpr/assets/zprbudgets/`

function DakBanglo() {
    TabTittle('ডাকবাংলো | জেলা পরিষদ, রাজশাহী।')
    const API = `https://www.zprajshahi.gov.bd/zpr/data/banglo`
    const [mainData, setmainData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const getAPIData = async (api) => {
        try {
            const res = await axios.get(api)
            const Data = await res.data
            setmainData(Data)
            setIsLoading(false)
        } catch (error) {
            console.log(error.message);
        }
    }
    useEffect(() => {
        getAPIData(API)
    }, []);

    return (
        <>
            <ToploaderBar />
            <div className='colam'>
                <div className="pageTittle">
                    <h2><Link to="/" >Hme</Link>/Dakbanglo</h2>
                </div>
                <div className="newcContent">
                    <div className="tittle"><h1>ডাকবাংলো</h1> <button title='print'><img src={printImg} alt="" /></button></div>
                    <div className="scroll-x">
                        <table >
                            <tr className='header'>
                                <th>ডাকবাংলোর নাম</th>
                                <th>কেয়ারটেকারের নাম</th>
                                <th>মোবাইল নম্বর</th>
                            </tr>
                            {
                                isLoading
                                    ?
                                    <Skli_Dakbanglo/>
                                    :
                                    <>
                                        {
                                            mainData.map(e => {
                                                const { name, caretaker, mobile } = e
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>{name}</td>
                                                            <td className='text-center' >{caretaker}</td>
                                                            <td className='text-center' >{mobile}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }
                                    </>
                            }

                        </table>
                    </div>

                </div>
            </div>
            {/* <div className="optionsPart">
                <div className="comment">
                    <img src={CommentImg} alt="" />
                </div>
            </div> */}
        </>
    )
}

export default DakBanglo
