import React from 'react'
import { Link } from 'react-router-dom'

function SideImg({tittle,img,alt}) {
    return (
        <>
            <div className="memderCard helpline">
                <div className="tittle">{tittle}</div>
                <div className="img">
                    <img src={img} alt={alt} />
                </div>
            </div>
        </>
    )
}

export default SideImg
