import React from 'react'
import Skeleton from 'react-loading-skeleton'

function SkliPhotoGlry() {
    return (
        <>
            <div className="card">
                <div className="imgPart">
                    <Skeleton width={'100%'} height={'250px'} />
                </div>
                <div className="tittle"><Skeleton baseColor='#4ba614' /></div>
            </div>
            <div className="card">
                <div className="imgPart">
                    <Skeleton width={'100%'} height={'250px'} />
                </div>
                <div className="tittle"><Skeleton baseColor='#4ba614' /></div>
            </div>
            <div className="card">
                <div className="imgPart">
                    <Skeleton width={'100%'} height={'250px'} />
                </div>
                <div className="tittle"><Skeleton baseColor='#4ba614' /></div>
            </div>
            <div className="card">
                <div className="imgPart">
                    <Skeleton width={'100%'} height={'250px'} />
                </div>
                <div className="tittle"><Skeleton baseColor='#4ba614' /></div>
            </div>
        </>
    )
}

export default SkliPhotoGlry