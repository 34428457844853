import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import MainAPI from '../assets/APIs/mainApi'
import { useState } from 'react'
import TabTittle from '../components/TabTittle'
import CommentImg from "../assets/img/comment.gif"
import MainHeader from '../components/MainHeader'
import Iframe from 'react-iframe'
import ToploaderBar from '../components/ToploaderBar'

function Contact() {
    const [mainData, setMainData] = useState(MainAPI)
    TabTittle("যোগাযোগ | জেলা পরিষদ, রাজশাহী।")
    return (
        <>
            <ToploaderBar />
            <div className="ContentPart">
                <div className="pageTittle">
                    <h2><Link to="/" >Hme</Link>/Contact</h2>
                </div>
                <div className="tittle my-4">
                    <h1>Contact Us</h1>
                </div>
                <div className="contact">
                    <div className="grid">
                        <div className="map">
                            <div className="customTittle">
                                <h1>Google Map</h1>
                            </div>
                            <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1978.7084048020758!2d88.56233517870741!3d24.372912758378376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fbef01478d0e2f%3A0xebefab28dca61218!2sRajshahi%20District%20Council!5e0!3m2!1sen!2sbd!4v1676698567077!5m2!1sen!2sbd" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></Iframe>
                        </div>
                        <div className="massege">
                            <div className="customTittle">
                                <h1>Send Massege</h1>
                            </div>
                            <form action="" method="post">
                                <input type="text" placeholder='Name' name="name" id="" />
                                <input type="email" placeholder='Email' name="email" id="" />
                                <input type="text" placeholder='Subject' name="subject" id="" />
                                <textarea name="massege" id="" cols="30" rows="10" placeholder='Massege'></textarea>
                                <button type="submit">Send</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="optionsPart">
                <div className="comment">
                    <img src={CommentImg} alt="" />
                </div>
            </div> */}
        </>
    )
}

export default Contact
