import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Skli_Discribe from '../components/skeletonComp/Skli_Discribe'
import TabTittle from '../components/TabTittle'
import ToploaderBar from '../components/ToploaderBar'

const imgURL = `https://www.zprajshahi.gov.bd/zpr/assets/zprstaff/`;
function Discribe() {
    TabTittle('বর্ণনা | জেলা পরিষদ, রাজশাহী।')
    const [mainData, setMainData] = useState([]);
    const API = `https://www.zprajshahi.gov.bd/zpr/data/staff/`;
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(true)

    const singleStrafData = async (api) => {
        const res = await axios.get(api)
        const Data = await res.data;
        setMainData(Data)
        setIsLoading(false)
    }

    useEffect(() => {
        singleStrafData(`${API}?id=${id}`)
    }, [id])

    return (
        <>
            <ToploaderBar />
            <div className="mainContentPartk">
                {
                    isLoading
                        ?
                        <Skli_Discribe />
                        :
                        <div className='colam describe'>
                            <h1>{mainData.name}</h1>
                            <div className="content">
                                <div className="memberDit">
                                    <div className="imgpart rounded">
                                        <img src={imgURL + mainData.image} alt="" />
                                    </div>
                                    <div className="bayo">
                                        <p>{mainData.staff_details}</p>
                                    </div>
                                </div>
                                <div className="maberTable">
                                    <table>
                                        <tr className='header'>
                                            <th>নাম</th>
                                            <th>{mainData.name}</th>
                                        </tr>
                                        <tr>
                                            <td>পদবী</td>
                                            <td>{mainData.designation}</td>
                                        </tr>
                                        <tr>
                                            <td>টেলিফোন নম্বর</td>
                                            <td>{mainData.phone}</td>
                                        </tr>
                                        <tr>
                                            <td>ই-মেইল ঠিকানা</td>
                                            <td>{mainData.email1}</td>
                                        </tr>
                                        <tr>
                                            <td>বর্তমান কর্মস্থলে যোগদানের তারিখ</td>
                                            <td>{mainData.joining_date}</td>
                                        </tr>
                                        <tr>
                                            <td>নিজ জেলা</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>রক্তের গ্রুপ</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>রাজনৈতিক পরিচয়</td>
                                            <td></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                }

            </div>
            {/* <div className="optionsPart">
                <div className="comment">
                    <img src={CommentImg} alt="" />
                </div>
            </div> */}
        </>
    )
}

export default Discribe
